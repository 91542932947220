import React from 'react'
import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MainContainer from '../../../container/main'
import SEO from '../../../components/seo'
import Container from '../../../components/container'
import ContentBannerWithCTA from '../../../components/content-banner-with-cta'
import { heroContainer, contentBlock } from './index.module.scss'
import { UseCaseBlock, UseCaseCategory, UseCaseHero, UseCasesContainer, UseCaseTeaser } from '../../../components/content-use-cases'
import ContentBlockSecondary from '../../../components/content-block-secondary'
import ContentBlockSmall from '../../../components/content-block-small'
import ContentBlockLargeWithGallery from '../../../components/content-block-large-gallery'
import Subheadline from '../../../components/content-subheadline'

const IndexPage = ({ data, location }) => (
  <MainContainer>
    <SEO title="Intranet from your existing apps" />

    <Container fullWidth style={{ background: 'var(--primary)' }}>
      <UseCaseCategory title="Intranet" />
    </Container>

    <Container>
      <UseCaseHero
        title="Build your tailor-made Intranet from your existing apps"
        description="Many companies still use different apps for internal communication (like Slack, Microsoft Teams etc.) and apps for knowledge or information sharing (like Confluence, SharePoint etc.). All these apps have a good purpose because they are actively used by your employees. Wouldn't it be a good idea to unite your valuable information pools into one central solution? With a Digital Hub you can connect all your existing apps and create a customized intranet solution for your organization. Your employees would be happy to find every information from all your apps in one single place."
        actionLinkText="Get started – It's free!"
        actionLinkIsExternal
        actionLink="https://workplace.basaas.com/register/?utm_source=basaas-com&utm_medium=hero&utm_campaign=signup-basaas-2022&utm_content=lp-intranet-from-existing-apps "
        demoButton
      />
    </Container>

    <Container style={{ padding: '80px 0 0' }}>
      <Subheadline headline="The digital headquarters with all your existing solutions" />
      <span style={{ display: 'block', marginTop: '-8px', fontSize: '16px', fontWeight: '600', color: 'var(--primary)' }}>
        Connect all your existing collaboration und knowledge apps into a Best-of-Breed Intranet and offer your people a unified digital headquarters.
      </span>
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'Search',
            title: 'Share knowledge with employees',
            description: `Connect your knowledge platforms (like SharePoint and Confluence etc.) or your existing wikis and let your people find information cross app easily.`,
            media: <StaticImage src="../../../assets/images/basaas-2022-search.png" alt="Share knowledge with employees" placeholder="blurred" />,
          },
        ]}
        alignVertically
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'News',
            title: 'Improve your corporate communication',
            description: `Your staff can find information faster with an overarching search that searches your connected apps like Outlook, Teams, OneDrive, Sharepoint, etc.`,
            media: <StaticImage src="../../../assets/images/basaas-2022-news-preview.png" alt="Improve your corporate communication" placeholder="blurred" />,
            comingSoon: true,
          },
        ]}
        alignVertically
        // reverse
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'MyDay',
            title: 'Entire daily business at a glance',
            description: `Employees receive notifications about your corporate news and from all connected apps in one central place and they never miss the information anymore.`,
            media: <StaticImage src="../../../assets/images/basaas-2022-myday.png" alt="Entire daily business at a glance" placeholder="blurred" />,
          },
        ]}
        alignVertically
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'Apps',
            title: 'All your apps in one solution',
            description: `Combine all apps for your people into your digital headquarters and help them to optimize their digital daily business.`,
            media: <StaticImage src="../../../assets/images/basaas-2022-apps.png" alt="All your apps in one solution" placeholder="blurred" />,
            // comingSoon: true,
          },
        ]}
        alignVertically
        // reverse
      />
    </Container>

    <Container style={{ background: 'var(--base-background)', padding: '160px 0 80px', marginTop: '160px' }}>
      <h2 style={{ fontSize: '24px', margin: '0 0 40px', fontWeight: 800 }}>Explore how to optimize your business for</h2>
      <UseCaseBlock location={location.pathname} />
    </Container>

    {/* BLOCK SECURITY  */}
    <Container style={{ background: 'var(--base-background)' }}>
      <ContentBlockSecondary
        headline="Enterprise productivity at the highest security level"
        description="The Basaas security concept is based on international best in class standards and EU requirements and guarantees the highest enterprise security level."
        // actionLinkText="Learn more"
        // actionLink="/tasks"
        item_01={
          <ContentBlockSmall
            key="1"
            headline="Fully GDPR compliant"
            description="Basaas is fully comply with European data security regulations including GDPR. For European companies, we guarantee that data storage takes place exclusively in the EU."
            // actionLinkText="Learn more"
            // actionLink="/workplace"
            media={<StaticImage src="../../../assets/feature-icons/gdpr-compliant.png" alt="Fully GDPR compliant" placeholder="blurred" />}
          />
        }
        item_02={
          <ContentBlockSmall
            key="2"
            headline="Proven enterprise security"
            description="At Basaas, we take our customers’ security very seriously and have extensive experience serving enterprise clients with complex security requirements."
            // actionLinkText="Learn more"
            // actionLink="/workplace"
            media={<StaticImage src="../../../assets/feature-icons/proven-enterprise-security.png" alt="Proven enterprise security" placeholder="blurred" />}
          />
        }
      />
    </Container>

    <Container style={{ background: 'var(--base-background)', padding: '80px 0' }}>
      <ContentBannerWithCTA />
    </Container>
  </MainContainer>
)

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        teamsCount
      }
    }
  }
`
